import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import SystemConfiguration from "services/systemConfiguration";

export const SystemContext = createContext(null);

export function SystemProvider({ children }) {
  const [system, setSystem] = useState({});

  useEffect(() => {
    SystemConfiguration.systemConfigurationGetDetail().then(({ data } = {}) => {
      if (data) setSystem(data);
    });
  }, []);

  return (
    <SystemContext.Provider value={{ system }}>
      {children}
    </SystemContext.Provider>
  );
}

export function useSystem() {
  return useContext(SystemContext);
}
