// import { handleGetAppConfigurationSuccess } from "actions/appAction"
import ForgotPass from "Page/ForgotPass";
import { Introduce } from "Page/Introduce";
import DetailBranch from "Page/Organization/detailBranch";
import HistoryPromotion from "Page/Promotions/HistoryPromotion";
import MyTeam from "Page/Promotions/MyTeam";
import Promotion from "Page/Promotions/Promotions";
import Tuturial from "Page/Promotions/Tuturial";
import Saving from "Page/Saving";
import Settings from "Page/Settings";
import TransactionHistory from "Page/TransactionHistory";
import React, { lazy, Suspense, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./../src/Page/Layout";
import {
  IconBranch,
  IconLeaderBoard,
  IconNotification,
  IconOrganizationSmall,
  IconPacket,
  IconProfileIcon,
  IconRechargeHistory,
  IconSaving,
  IconSettings,
  IconSignout,
  IconSupport,
  IconWallet,
  ThunderButton2,
} from "./assets/icons/index";
const NotificationDetail = lazy(() =>
  import("./../src/Page/Notifications/Detail")
);
const RuleDescription = lazy(() => import("./../src/Page/RuleDescription"));
const AgencyCooperation = lazy(() => import("./Page/AgencyCooperation"));
const CompanyQualification = lazy(() => import("./Page/CompanyQualification"));
const Home = lazy(() => import("./../src/Page/Home"));
const Register = lazy(() => import("./../src/Page/Register"));
const Login = lazy(() => import("./../src/Page/Login"));
const Game5DLotre = lazy(() => import("./Page/Game5DLotre"));
const AllBetWingo = lazy(() => import("./Page/GameWinGo/allBetWingo"));
const AllBetK3Lotre = lazy(() => import("./Page/GameK3Lotre/allBetK3Lotre"));
const AllBet5DLotre = lazy(() => import("./Page/Game5DLotre/allBet5DLotre"));
const BoughtMachine = lazy(() =>
  import("./../src/Page/TransactionHistory/BoughtMachine")
);
const DepositUSDT = lazy(() =>
  import("./../src/Page/TransactionHistory/DepositUSDT")
);
const ExchangeFAC = lazy(() =>
  import("./../src/Page/TransactionHistory/ExchangeFAC")
);
const ExchangePoint = lazy(() =>
  import("./../src/Page/TransactionHistory/ExchangePoint")
);
const ReceivePoint = lazy(() =>
  import("./../src/Page/TransactionHistory/ReceivePoint")
);
const ReceiveBTC = lazy(() =>
  import("./../src/Page/TransactionHistory/ReceiveBTC")
);
const ReceiveFAC = lazy(() =>
  import("./../src/Page/TransactionHistory/ReceiveFAC")
);
const WithdrawBTC = lazy(() =>
  import("./../src/Page/TransactionHistory/WithdrawBTC")
);
const WithdrawUSDT = lazy(() =>
  import("./../src/Page/TransactionHistory/WithdrawUSDT")
);
const Branch = lazy(() => import("./../src/Page/Branch"));
const Notifications = lazy(() => import("./../src/Page/Notifications"));
const Organization = lazy(() => import("./../src/Page/Organization"));
const LeaderBoard = lazy(() => import("./../src/Page/LeaderBoard"));
const Packet = lazy(() => import("./../src/Page/Packet"));
const Recharge = lazy(() => import("./../src/Page/Recharge"));
const Withdraw = lazy(() => import("./../src/Page/Withdraw"));
const WithdrawInfo = lazy(() => import("./../src/Page/WithdrawInfo"));
const Support = lazy(() => import("./../src/Page/Support"));
const Profile = lazy(() => import("./../src/Page/Profile"));
const ProfilePolicy = lazy(() => import("./../src/Page/Profile/policy"));
const ProfileIntroOverview = lazy(() =>
  import("./../src/Page/Profile/introOverview")
);
const ProfileCustomer = lazy(() => import("./../src/Page/Profile/customer"));
const ProfileAboutUs = lazy(() => import("./../src/Page/Profile/aboutUs"));
const InfoProfile = lazy(() => import("./../src/Page/Profile/infoProfile"));
const ProfileGuideUser = lazy(() => import("./../src/Page/Profile/guideUser"));
const BetRecordsHistory = lazy(() =>
  import("./../src/Page/Profile/betRecordsHistory")
);
const Management = lazy(() => import("./Page/Management"));
const Police = lazy(() => import("./../src/Page/Police"));
const ListPackageBonus = lazy(() => import("./../src/Page/ListPackageBonus"));
const Staking = lazy(() => import("./Page/Staking"));
const GameWindGo = lazy(() => import("./Page/GameWinGo"));
const GameK3Lotre = lazy(() => import("./Page/GameK3Lotre"));
const InterestTreasure = lazy(() => import("./Page/InterestTreasure"));
const InvestRecord = lazy(() => import("./Page/InvestRecord"));
const MakingMoney = lazy(() => import("./Page/MakingMoney"));
const InviteFriend = lazy(() => import("./Page/InviteFriends"));
const TeamReport = lazy(() => import("./Page/TeamReport"));
const BankCard = lazy(() => import("./Page/BankCard"));
const OfflineMessage = lazy(() => import("./Page/OfflineMessage"));
const RechargeRecord = lazy(() => import("./Page/RechargeHistory"));
const OrderHistory = lazy(() => import("./Page/OrderHistory"));
const Language = lazy(() => import("./Page/Language"));
const History = lazy(() => import("./Page/History"));
const ProfilePage = lazy(() => import("./Page/ProfilePage"));
const ChangePassword = lazy(() => import("./Page/ChangePassword"));
const PersonalData = lazy(() => import("./Page/PersonalData"));
const Widthdrawal = lazy(() => import("./Page/Widthdrawal"));
const ShipingAddress = lazy(() => import("./Page/ShipingAddress"));
const WithDrawalHistory = lazy(() => import("./Page/WithdrawalHistory"));
const MainHistory = lazy(() => import("./Page/TransactionHistory"));

export const routes = {
  login: {
    path: "/login",
    component: Login,
    isAuth: false,
    label: "Đăng nhập",
  },
  register: {
    path: "/register",
    component: Register,
    isAuth: false,
    label: "Đăng ký",
  },
  forgotPass: {
    path: "/forgot",
    component: ForgotPass,
    isAuth: false,
    label: "Quên mật khẩu",
  },
  police: {
    path: "/police",
    component: Police,
    isAuth: true,
    label: "Chính sách bảo mật",
  },
  offlineMessage: {
    path: "/offline-messages",
    component: OfflineMessage,
    isAuth: false,
    label: "Tin nhắn offline",
  },
  rechargeRecord: {
    path: "/recharge-record",
    component: RechargeRecord,
    isAuth: false,
    label: "Lịch sử nạp tiền",
  },
  orderHistory: {
    path: "/order-history",
    component: History,
    isAuth: false,
    label: "Lịch sử nạp tiền",
  },
  orderHistorySnd: {
    path: "/order-history-2",
    component: History,
    isAuth: false,
    label: "Lịch sử nạp tiền",
  }, 
  language: {
    path: "/language",
    component: Language,
    isAuth: false,
    label: "Ngôn ngữ",
  },
  history: {
    path: "/history",
    component: MainHistory,
    isAuth: false,
    label: "Lịch sử",
  },
  personalData: {
    path: "/personal-data",
    component: PersonalData,
    isAuth: false,
    label: "Dữ liệu cá nhân",
  },
  profile: {
    path: "/profile",
    component: ProfilePage,
    isAuth: false,
    label: "Cá nhân",
  },
  making_money: {
    path: "/making-money",
    component: MakingMoney,
    isAuth: false,
    label: "Kiếm tiền",
  },
  widthdrawal: {
    path: "/width-drawal",
    component: Widthdrawal,
    isAuth: false,
    label: "Rút tiền",
  },
  allBetWingo: {
    path: "/game-all-win-go",
    component: AllBetWingo,
    isAuth: true,
    label: " 5D Lotre",
    isHiddenFooter: true,
  },
  allBet5DLotre: {
    path: "/game-all-5d-lotre",
    component: AllBet5DLotre,
    isAuth: true,
    label: " 5D Lotre",
    isHiddenFooter: true,
  },
  allBetK3Lotre: {
    path: "/game-all-k3-lotre",
    component: AllBetK3Lotre,
    isAuth: true,
    label: " K3 Lotre",
    isHiddenFooter: true,
  },
  game5DLotre: {
    path: "/game-5d-lotre",
    component: Game5DLotre,
    isAuth: true,
    label: "Game 5D Lotre",
    isHiddenFooter: true,
  },
  promotion: {
    path: "/promotion",
    component: Promotion,
    isAuth: true,
    label: "Quảng bá đại lý",
    isTop: true,
    showFooterMobile: true,
  },
  historyPromotion: {
    path: "/promotionDes",
    component: HistoryPromotion,
    isAuth: true,
    label: "Lịch sử nhận",
    isTop: true,
    showFooterMobile: true,
  },
  myTeam: {
    path: "/myTeam",
    component: MyTeam,
    isAuth: true,
    label: "Đội của tôi",
    isTop: true,
    showFooterMobile: true,
  },
  tuturial: {
    path: "/tuturial",
    component: Tuturial,
    isAuth: true,
    label: "Hướng dẫn",
  },
  introdure: {
    path: "/leader-board",
    component: LeaderBoard,
    isAuth: true,
    label: "Giải thưởng",
    isTop: true,
  },
  managementWallet: {
    path: "/management/wallet",
    component: Management,
    isAuth: true,
    label: "Danh sách ví",
    isMenuItem: true,
    icon: <IconWallet />,
    className: "cosutmSelect",
  },
  managementPacket: {
    path: "/management/packet",
    component: Packet,
    isAuth: true,
    label: "Quảng bá đại lý",
    isMenuItem: true,
    icon: <IconPacket />,
    showFooterMobile: true,
  },
  managementPackageBonus: {
    path: "/management/package-notification",
    component: ListPackageBonus,
    isAuth: true,
    label: "Nhà máy",
    isMenuItem: true,
    icon: <ThunderButton2 />,
    showFooterMobile: true,
  },
  managementRechargeHistory: {
    path: "/management/transaction-history",
    component: TransactionHistory,
    isAuth: true,
    label: "Lịch sử ",
    mobileTitle: "Lịch sử giao dịch",
    isMenuItem: true,
    icon: <IconRechargeHistory />,
  },
  managementDepositHistory: {
    path: "/deposit-admin",
    component: DepositUSDT,
    isAuth: true,
    isHiddenFooter: true,
  },
  recharge: {
    path: "/Recharge",
    component: Recharge,
    isAuth: true,
    isSubItem: true,
    isHiddenFooter: true,
  },
  withdrawInfo: {
    path: "/withdraw-info",
    component: WithdrawInfo,
    isAuth: true,
    label: "Thông tin rút tiền",
    // isSubItem: true,
  },
  shipingAddress: {
    path: "/shiping-address",
    component: ShipingAddress,
    isAuth: true,
    label: "Thông tin rút tiền",
    // isSubItem: true,
  },
  managementDeposit: {
    path: "/Withdraw",
    component: Withdraw,
    isAuth: true,
    label: "Rút Tiền",
    // isSubItem: true,
  },

  managementWithdrawHistoryUSDT: {
    path: "/management/withdraw-history-usdt",
    component: WithdrawUSDT,
    isAuth: true,
    label: "Rút USDT",
    isSubItem: true,
  },
  managementViewHistoryFAC: {
    path: "/management/view-history-fac",
    component: ReceiveFAC,
    isAuth: true,
    label: "Nhận FAC",
    isSubItem: true,
  },
  managementUserExchangeFACHistory: {
    path: "/management/user-exchange-fac-history",
    component: ExchangeFAC,
    isAuth: true,
    label: "Quy đổi FAC",
    isSubItem: true,
  },
  managementUserReceivePOINTHistory: {
    path: "/management/user-receive-point-history",
    component: ReceivePoint,
    isAuth: true,
    label: "Nhận hoa hồng",
    isSubItem: true,
  },
  managementUserExchangePOINTHistory: {
    path: "/management/user-exchange-point-history",
    component: ExchangePoint,
    isAuth: true,
    label: "Đổi hoa hồng",
    isSubItem: true,
  },
  managementViewHistoryBTC: {
    path: "/management/view-history-btc",
    component: ReceiveBTC,
    isAuth: true,
    label: "Nhận BTC",
    isSubItem: true,
  },
  managementWithdrawHistory: {
    path: "/management/withdraw-history",
    component: WithdrawBTC,
    isAuth: true,
    label: "Rút BTC",
    isSubItem: true,
  },
  managementHistoryServicePackage: {
    path: "/management/history-service-package",
    component: BoughtMachine,
    isAuth: true,
    label: "Mua máy đào",
    isSubItem: true,
  },
  branch: {
    path: "/management/branch",
    component: Branch,
    isAuth: true,
    label: <FormattedMessage id="branch" />,
    isMenuItem: true,
    icon: <IconBranch />,
    showFooterMobile: true,
  },
  organization: {
    path: "/management/organization",
    component: Organization,
    isAuth: true,
    label: <FormattedMessage id="organization" />,
    isMenuItem: true,
    icon: <IconOrganizationSmall />,
  },
  notification: {
    path: "/management/notification",
    component: Notifications,
    isAuth: true,
    label: <FormattedMessage id="notification" />,
    isMenuItem: true,
    icon: <IconNotification />,
  },
  notificationDetail: {
    path: "/management/notification/:id",
    component: NotificationDetail,
    isAuth: true,
    isMenuItem: true,
    isHidden: true,
    label: <FormattedMessage id="notification" />,
  },
  leaderBoard: {
    path: "/management/leader-board",
    component: LeaderBoard,
    isAuth: true,
    label: <FormattedMessage id="leader_board" />,
    isMenuItem: true,
    icon: <IconLeaderBoard />,
  },
  saving: {
    path: "/management/saving",
    component: Saving,
    isAuth: true,
    label: <FormattedMessage id="saving" />,
    isMenuItem: true,
    icon: <IconSaving />,
  },
  managementBetRecordsHistory: {
    path: "/management/bet-record-profile",
    component: BetRecordsHistory,
    isAuth: true,
    label: "Lịch sử mua",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementInfoProfile: {
    path: "/management/info-profile",
    component: InfoProfile,
    isAuth: true,
    label: "Tài khoản",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementProfileAboutUs: {
    path: "/management/profile-about-us",
    component: ProfileAboutUs,
    isAuth: true,
    label: "Về chúng tôi",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementProfileIntroOverview: {
    path: "/management/profile-intro-overview",
    component: ProfileIntroOverview,
    isAuth: true,
    label: "Thảo luận tiết lộ rủi ro",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementProfilePolicy: {
    path: "/management/profile-policy",
    component: ProfilePolicy,
    isAuth: true,
    label: "Chính sách bảo mật",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementProfileCustomer: {
    path: "/management/profile-customer",
    component: ProfileCustomer,
    isAuth: true,
    label: "Khách hàng",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementProfileGuideUser: {
    path: "/management/profile-guide-user",
    component: ProfileGuideUser,
    isAuth: true,
    label: "Khách hàng",
    isMenuItem: false,
    icon: <IconProfileIcon />,
  },
  managementProfile: {
    path: "/management/profile",
    component: Profile,
    isAuth: true,
    label: "Tài khoản",
    isMenuItem: true,
    icon: <IconProfileIcon />,
  },
  gameWinGo: {
    path: "/gameWinGo",
    component: GameWindGo,
    isAuth: true,
    label: "Game Win Go",
    isHiddenFooter: true,
  },
  gameK3Lotre: {
    path: "/gameK3Lotre",
    component: GameK3Lotre,
    isAuth: true,
    label: "Game K3 Lotre",
    isHiddenFooter: true,
  },
  settings: {
    path: "/settings",
    component: Settings,
    isAuth: true,
    label: "Cài đặt",
    isMenuItem: true,
    icon: <IconSettings />,
  },
  support: {
    path: "/support",
    component: Support,
    isAuth: true,
    label: "Hỗ trợ",
    icon: <IconSupport />,
  },
  management: {
    path: "/management",
    component: Management,
    isAuth: true,
    isTop: true,
    label: "Quản lý",
    isMenuItem: true,
  },
  signout: {
    path: "/signout",
    label: "Đăng xuất",
    icon: <IconSignout />,
    isAuth: false,
  },
  contact: {
    path: "/contact",
    component: Support,
    isAuth: true,
    label: "Liên hệ",
    isMobileHidden: true,
    isTop: true,
  },
  detailBranch: {
    path: "/detail-branch",
    component: DetailBranch,
    isAuth: true,
  },
  staking: {
    path: "/management/staking",
    isAuth: true,
    component: Staking,
  },

  introduce: {
    path: "/introduce",
    component: Introduce,
    isAuth: true,
    label: "Giới thiệu",
    isTop: true,
    isHiddenFooter: true,
    isFullWidth: true,
  },
  ruleDescription: {
    path: "/rule-description",
    component: RuleDescription,
    isAuth: false,
    label: "Rule description",
    isTop: true,
    isFullWidth: true,
    isHiddenFooter: true,
  },
  agencyCooperation: {
    path: "/agency-cooperation",
    isAuth: true,
    isFullWidth: true,
    component: AgencyCooperation,
    isHiddenFooter: true,
  },
  companyQualification: {
    path: "/company-qualification",
    isAuth: true,
    isFullWidth: true,
    component: CompanyQualification,
    isHiddenFooter: true,
  },

  invest: {
    path: "/invest",
    isAuth: true,
    component: InterestTreasure,
    isHiddenFooter: true,
  },
  investRecord: {
    path: "/invest/record",
    isAuth: true,
    component: InvestRecord,
    isHiddenFooter: true,
  },

  invite: {
    path: "/invite",
    isAuth: true,
    component: InviteFriend,
    isHiddenFooter: true,
  },

  teamReport: {
    path: "/team",
    isAuth: true,
    component: TeamReport,
  },
  bindBank: {
    path: "/bind-bank",
    isAuth: true,
    component: BankCard,
    isHiddenFooter: true,
  },

  changePsw: {
    path: "/change-password",
    isAuth: true,
    component: ChangePassword,
    isHiddenFooter: true,
  },

  withDrawHistory: {
    path: "/withdraw-history",
    isAuth: true,
    component: WithDrawalHistory,
    isHiddenFooter: false,
  },
};

function App() {
  const isUserLoggedIn = useSelector((state) =>
    state.member ? state.member.isUserLoggedIn : false
  );
  console.log("isUserLoggedIn", isUserLoggedIn);
  if (isUserLoggedIn) {
    routes.home = {
      path: "/",
      component: Home,
      isAuth: true,
      label: "Trang chủ",
    };
  } else {
    routes.home = {
      path: "/",
      component: Login,
      isAuth: false,
      label: "Đăng nhập",
    };
  }

  

  return (
    <Router>
      <Suspense
        fallback={
          <div>
            <FormattedMessage
              id="LOADING_TEXT"
              defaultMessage={"Đang tải dữ liệu..."}
            />
          </div>
        }
      >
        <Switch>
          {Object.keys(routes).map((key, index) => {
            if (isUserLoggedIn && routes[key].isAuth) {
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      routes={routes}
                      isAuth={routes[key].isAuth}
                      isMenuItem={routes[key].isMenuItem}
                      isSubMenu={routes[key].isSubMenu}
                      isSubItem={routes[key].isSubItem}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      isHiddenFooter={routes[key].isHiddenFooter}
                      className={`${routes[key].className || "HOME"}`}
                    />
                  )}
                />
              );
            } else if (!routes[key].isAuth) {
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      routes={routes}
                      isAuth={routes[key].isAuth}
                      isFullWidth={routes[key]?.isFullWidth}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      className={routes[key].isHeader ? "HOME" : "LOGIN"}
                      isHiddenFooter={routes[key].isHiddenFooter}
                    />
                  )}
                />
              );
            }
          })}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
