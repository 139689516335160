import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { number_format } from "../../helper/common";
import Loader from "../../components/Loader";
import { notification, DatePicker } from "antd";
import moment from "moment";
// import TransactionRender from "components/Loader/transactionRender";
// import PaymentWithdrawTransaction from "../../services/paymentWithdrawTransaction";
import { FormattedMessage, useIntl } from "react-intl";
import HeaderPageSticky from "components/HeaderPageSticky";
import useDateFilter from "hooks/useDateFilter";
import style from "./style.module.css";
import PaymentWithdrawTransaction from "services/paymentWithdrawTransaction";
import TransactionRender from "components/Loader/transactionRender";
import Table from "components/Promotion/Table/Table";

const DEFAULT_FILTER = {
  skip: 0,
  limit: 20,
  order: {
    key: "createdAt",
    value: "desc",
  },
};

const Status = ({ status }) => {
  const build = {
    approved: {
      bg: "#3498db",
      text: <FormattedMessage id="withdraw_approved" />,
    },
  }[status];
  if (!build) return null;
  return (
    <span
      className="d-inline-block text-white px-2"
      style={{ fontSize: 10, borderRadius: 8, background: build?.bg }}
    >
      {" "}
      {build?.text}{" "}
    </span>
  );
};

const RowRecord = ({ code, status, amount }) => (
  <div
    style={{ minHeight: 60, borderTop: "1px solid #BDBDBD" }}
    className="d-flex justify-content-between p-3 bg-white"
  >
    <div>
      <p style={{ fontSize: 18 }}>{code}</p>
      <Status status={status} />
    </div>
    <div className="h5 text-success">{amount}</div>
  </div>
);


function WithDrawalHistory(props) {
  const { headerHide } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [dataList, setDataList] = useState({ data: [], total: 0 });

  const intl = useIntl();
  const t = useCallback((id) => intl.formatMessage({ id }), [intl]);

  const {
    startDate,
    endDate,
    onChangeEndDate,
    onChangeStartDate,
    filter,
    setFilter,
    onKeyDown,
    disabledDate,
  } = useDateFilter({ callback: withDrawalHistory });

  function withDrawalHistory(filter) {
    setIsVisible(true);
    PaymentWithdrawTransaction.withdrawHistory(filter).then((result) => {
      const { isSuccess, message, data } = result;
      console.log({ result });
      setIsVisible(false);
      if (!isSuccess || !data) {
        notification["error"]({
          message: "",
          description: t(message) || t("something_wrong"),
        });
        return;
      } else {
        setDataList(data);
      }
    });
  }

  useEffect(() => {
    withDrawalHistory();
  }, []);

  const columns = [
    {
      title: t("time"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row, index) => {
        const { createdAt } = row;
        return (
          <div className="d-flex justify-content-center align-items-center">
            <span className="management__box__coin recharge_history__normal_text">
              {moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        );
      },
    },
    {
      title: t("deposit_amount"),
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (text, row, index) => {
        const { paymentAmount } = row;
        return (
          // <div className="d-flex w-100 align-items-center">
          number_format(paymentAmount)
          /* <span className="management__ox__coin recharge_history__normal_text">
            </span> */
          // </div>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text, row, index) => {
        const { paymentStatus } = row;
        return (
          <div className="d-flex align-items-center justify-content-left">
            <span className="management__box__coin recharge_history__normal_text">
              {
                TransactionRender({ paymentStatus, intl })
              }
            </span>
          </div>
        );
      },
    }
  ];

  return (
    <>
      {headerHide ? '' : (<HeaderPageSticky
        title={t("withdraw_profile")}
        fillColor
        extra="notify"
      />)}

      <section
        className={`${style.container} management recharge_history with_draw_history container_responsive ${headerHide ? '' : 'mt-hd'} pt-4`}
      >
        <div className="management__box">
          <div className="recharge_history__header">
            <div className="recharge_history__group_select_date">
              <DatePicker
                inputReadOnly={true}
                onKeyDown={(e) => onKeyDown(e)}
                value={startDate}
                format="YYYY-MM-DD"
                placeholder={`${t('from')}: YY-MM-DD`}
                onChange={(date) => onChangeStartDate(date)}
              />
              <DatePicker
                inputReadOnly={true}
                onKeyDown={(e) => onKeyDown(e)}
                value={endDate}
                disabledDate={disabledDate}
                placeholder={`${t('to')}: YY-MM-DD`}
                format="YYYY-MM-DD"
                className="recharge_history__select_date"
                onChange={(date) => onChangeEndDate(date)}
              />
            </div>
          </div>
          <Table
            dataSource={dataList.data}
            scroll={{ x: 768 }}
            columns={columns}
            pagination={{
              onChange: (page) => {
                const skip = (page - 1) * filter.limit;
                const newFilter = {
                  ...filter,
                  skip,
                };
                setFilter(newFilter);
                withDrawalHistory(newFilter);
              },
              total: dataList.total,
              pageSize: filter.limit,
              showSizeChanger: true,
            }}
          />
        </div>
        {isVisible ? <Loader /> : null}
      </section>
    </>
  );
}
export default WithDrawalHistory;
