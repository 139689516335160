import { handleReadTutorial } from "actions";
import { Button, Modal } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.css";

export default function ModalIntroduceNewbie() {
  const {memeber} = useSelector((state)=> state)
  const [visible, setVisible] = useState(!!memeber?.isReadTutorial);
  const {formatMessage: f} = useIntl()
  const dispatch = useDispatch()

  const onConfirm = () => {
    dispatch(handleReadTutorial())
    setVisible(false)
  }

  return (
    <Modal
      centered
      wrapClassName={style.wrapper}
      bodyStyle={{ padding: 0, height: "fit-content", width: 300 }}
      footer={null}
      visible={visible}
      closable={false}
      onCancel={onConfirm}
    >
      <div className={classNames('d-flex justify-content-between flex-column',style.introduce__content) }>
        <button className={style.close}><MdClose size={18} onClick={() => setVisible(false)} /></button>
        <img src="/introduce.png" width={300} height={100} alt="introudce" />

        <p className="py-4 px-3 text-center" style={{ fontSize: 16  }}>
            {f({id: 'introduce_newbie'})}
        </p>
        <div>
            <hr className="p-0 m-0"/> 
            <div className="p-3">
                <Button onClick={onConfirm} className={style.btnConfirm}>{f({ id: 'confirm' })}</Button>
            </div>
        </div>
      </div>
    </Modal>
  );
}
