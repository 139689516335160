import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import "./index.scss"
function Loader({ title }) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#F5493A"
      }}
      spin
    />
  );
  return (
    <div className="Loader d-flex flex-column">
      {title && <h5 style={{ color: '#F5493A' }}>{title}</h5>}
      <Spin indicator={antIcon} />
    </div>
  )
}
export default Loader;