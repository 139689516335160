import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import RechargeHistory from './../RechargeHistory';
import { useHistory } from "react-router-dom";
import DepositHistory from './../WithdrawalHistory';

import { useIntl } from 'react-intl';
import Header from '../../components/Header';
export default function TransactionHistory({ defaultActiveKey = "1" }) {
  const history = useHistory()
  const onChangeTab = () => {

  }
  return (
    <section className="management packet mb-0">
      <Tabs className="bg-gray profile__tab" defaultActiveKey={defaultActiveKey} onChange={onChangeTab}>
        <Tabs.TabPane tab={"Lịch sử nạp tiền"} key="1">
          <RechargeHistory headerHide={true} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Lịch sử rút tiền"} key="2">
          <DepositHistory headerHide={true} />
        </Tabs.TabPane>
      </Tabs>
    </section>
  )
}