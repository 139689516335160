
import { HISTORY_WINGO } from '../constants/history'
let initialState = {
  historyList: [],
}
export default function userReducer(state = initialState, action) {

  switch (action.type) {
    case HISTORY_WINGO: {
      if (action.data) {
        return {
          ...state,
          ...action.data
        }
      }
      return {}
    }
    default:
      return state
  }
}

