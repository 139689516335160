import { routes } from "App";
import React, { memo, useState, useEffect } from "react";
import cn from "classnames";
import style from "./style.module.css";
import SystemConfiguration from '../../services/systemConfiguration'
import {
  MdHome,
  MdPlaylistAddCheck,
  MdSwipe,
  MdChat,
  MdPerson,
} from "react-icons/md";

const MenuFooter = ({ location, history }) => {
  const [system, setsystem] = useState({});
  useEffect(() => {
    SystemConfiguration.systemConfigurationGetDetail().then((res)=>{
      const {data, isSuccess} = res
        if(isSuccess){
          setsystem(data)
        }
      }
    )

  }, [])
  const LIST_NAVIGATE = [
    {
      icon: <MdHome />,
      title: "",
      path: routes.home.path,
    },
    {
      icon: <MdPlaylistAddCheck />,
      title: "",
      path: routes?.orderHistory?.path,
    },
    {
      icon: <MdSwipe />,
      title: "",
      path: routes?.making_money?.path,
    },
    {
      icon: <MdChat />,
      title: "",
      path: "/null",
      link: system?.supportChatUrlVI
    },
    {
      icon: <MdPerson />,
      title: "",
      path: routes?.profile?.path,
    },
  ];


  return (
    <footer
      className={cn(
        "d-block  p-0 fixed-bottom d-flex justify-content-center",
        style.footer__mobile
      )}
    >
      {LIST_NAVIGATE.map((route) => {
        const isActive =
          location?.pathname?.split("/").pop() === route.path.split("/").pop();
        return (
          <div
            key={route.path}
            className={cn(style.footer__item, {
              [style.active]: isActive,
            })}
            role="button"
            onClick={() => {
              !(route.link) ? history.push(route?.path) :  window.open(route.link);
            }}
          >
            {route.icon}
          </div>
        );
      })}
    </footer>
  );
};

export default memo(MenuFooter);
