import { GiftFilled, CopyFilled } from "@ant-design/icons";
import { IconLink, IconSendSmall, OrganizationOutlined } from "assets/icons";
import HeaderPageSticky from "components/HeaderPageSticky";
import { useSystem } from "context/SystemContext";
import { useUser } from "context/UserContext";
import { simpleCopyToClipboard } from "helper/common";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

export const Introduce = () => {
  // useIntl template
  const intl = useIntl();
  const { system } = useSystem();
  const t = useCallback(
    (id) => {
      return intl.formatMessage({ id });
    },
    [intl]
  );
  return (
    <div>
      <HeaderPageSticky title={t("introduce")} textBack={t("back")} />
      <div
        style={{ background: "#ecf0f1", paddingTop: 70 }}
        className="vh-100 px-5"
      >
        <div style={{ borderRadius: 10 }} className="bg-white p-2">
          <p id="date" style={{ fontSize: 16, color: "#bdc3c7" }}>
            {moment(system?.updatedAt).format("YYYY-MM-DD hh:mm:ss")}
          </p>
          <p style={{ fontSize: 22 }} className="mt-2">
            {ReactHtmlParser(system?.introAboutUs)}
          </p>
        </div>
      </div>
    </div>
  );
};
