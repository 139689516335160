import { Button } from "antd";
import { useSystem } from "context/SystemContext";
import React from "react";
import BuddleIcon from "./buddle-icon";

export default function BuddleChat() {
  const { system } = useSystem();
  console.log({ system });
  return (
      <Button
        shape="circle"
        href={system?.supportChatUrlVI}
        target="_blank"
        style={{ height: 60, width: 60, bottom: 80, right: 20, position: "fixed", zIndex: 99 }}
        className="d-flex align-items-center justify-content-center text-white"
        icon={<BuddleIcon style={{ marginTop: 5 }} />}
      />
  );
}
